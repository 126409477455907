.fascia-pronto {
  padding: 35px 0 25px;

  h2 {
    margin-bottom: 28px;
  }

  @include breakpoint (large) {
    padding: 65px 0 35px;
  }
}