.button {
  font-size: 18px;
  text-transform: uppercase;
  transition: all .5s;

  &.blu {
    border: 1px solid $blu;
    background-color: $white;
    color: $blu;

    &:hover {
      border: 1px solid $white;
      background-color: $blu;
      color: $white;      
    }
  }
}

p {
  font-size: 20px;
  line-height: 28px;
  a {
    font-weight: bold;
  }
}