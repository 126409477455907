.fascia-footer {
  padding: 20px 0 30px;
  .copy {
    padding: 20px 0 0;
    font-size: 12px;
    color: $bluscuro;
  }
  .footer-links {
    font-size: 14px;
    line-height: 20px;
  }
}