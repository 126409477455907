.fascia-webform {
  .grid-container {
    @include breakpoint (large) {
      background-image: linear-gradient(90deg, $grigioE2 0%, $grigioE2 50%, $bluscuro 50%, $bluscuro 100%);
    }
  }
  .webform-left {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $grigioE2;

    h3 {
      margin-bottom: 11px;
      color: $blutext;
      line-height: 30px;

      @include breakpoint (large) {
        line-height: 40px;
      }
    }
    img {
      margin-top: 14px;
      width: 100%;
    }

    @include breakpoint (xlarge) {
      padding-top: 55px;
      padding-bottom: 55px;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
    }    
  }
  .webform-right {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $bluscuro;

    h2 {
      margin-bottom: 30px;
      color: $white;
    }

    @include breakpoint (xlarge) {
      padding-top: 55px;
      padding-bottom: 55px;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
    }
  }

  form {
    position: relative;
    #frmNome { margin-top: 0; }

    [type='text'], [type='password'], 
    [type='date'], [type='datetime'], 
    [type='datetime-local'], [type='month'], 
    [type='week'], [type='email'], [type='number'], 
    [type='search'], [type='tel'], [type='time'], 
    [type='url'], [type='color'], textarea {  
      height: auto;  
      padding: 13px 20px;
      font-weight: bold;
    }
    .submitBox {
      display: flex;

      flex-direction: column;

      @include breakpoint (large) {
        flex-direction: row;
      }

      div.privacy-input {
        position: relative;
        display: block;
        margin: 0;
        
        label {
          position: relative;
          padding-right: 0;
          padding-left: 30px;
          margin-bottom: 10px;
          margin-left: 0;
          margin-right: 0;
          vertical-align: top;
          font-size: rem-calc(13);
          line-height: rem-calc(15);
          color: $white;
          a { 
            color: $blu; 
            &:hover {
              text-decoration: underline;
            }
          }
          &:not(.error) {
            //checkbox
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              border: 0 none;
              border-radius: 0;
              background: $white;
              transition: all 0.3s ease-in-out;
              vertical-align: top;
            }
            // spunta checked
            @include icon('checked', 'after') {
              display: inline-block;
              position: absolute;
              top: 0; 
              left: 4px; 
              opacity: 0;
              transition: all 0.3s ease-in-out;
              transform: translate(0, 50%);
              font-size: 10px;
              line-height: 10px;
              color: $blu;
            }
          }

          @include breakpoint (large) {
            padding-right: 30px;
          }
        }
        
        .privacy-input-checkbox {
          cursor: pointer;
          vertical-align: top;
          color: $bluscuro;
        }

        input[type='checkbox'] {
          position: absolute;
          clip: rect(1px, 1px, 1px, 1px);
          margin: 0;
          &:not(:checked):hover {
            & + label:before {
              box-shadow: inset 0 0 0px 2px #27b0ff;
            }
          }
        }        
      }

      input[type='checkbox']:checked + label:not(.error) {
        &:before {
          background-color: $blu;
        }
        &:after { 
          opacity: 1;
          color: $white;
        }
      }
      button.ctaSubmit {
        display: inline-block;
        padding: 0 50px;
        margin: auto 0;
        background: $blu;
        border-radius: 2px;
        vertical-align: top;

        font-weight: bold;
        font-size: rem-calc(18);
        line-height: 50px;
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover { 
          background: $white;
          color: $blu; 
        }
      }
    }

    input {
      &.error {
        border: 1px solid red;
        background-color: #ffdcdc;
      }
      &[aria-invalid="false"] {
        border: 1px solid green;
        background-color: #e4ffe6;
      }
    }
    
    label {
      &.error {
        position: absolute;
        margin-top: -14px;
        margin-bottom: 2px;
        margin-right: 6px;
        font-size: rem-calc(10);
        font-weight: bold;
        line-height: 1;
        white-space: nowrap;
        color: $white;
      }
      &#frmPrivacy-error {
        position: relative;
        margin-top: 10px;
      }
    }
  }


  @include breakpoint (medium down) {
    .formWrapper {
      .cellInfos {
        h2 {
          font-size: rem-calc(40);
          line-height: rem-calc(45);
        }
        p.paragH2, p.paragH2 > a {
          font-size: rem-calc(35);
          line-height: rem-calc(40);
        }
      }
    }
  }

  @include breakpoint (medium only) {
    .formWrapper {
      .cellInfos {
        padding-right: 0;
        width: 80%;
        margin: 0 auto;
        h2 {
          font-size: rem-calc(40);
          line-height: rem-calc(45);
        }
      }
      
      .cellForm {
        padding-top: 30px;
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  @include breakpoint (small only) {
    padding-top: 0px;
    .formWrapper {
      .cellInfos {
        padding-right: 0;
        text-align: center;
      }
      
      .cellForm {
        padding-top: 30px;
      }
      
      form {
        .submitBox {
          display: block;
          p { padding-right: 0; }
          button.ctaSubmit { 
            margin-top: 35px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
          label.error {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}