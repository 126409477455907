.fascia-header {
  padding: 40px 0 40px;
  background: url("../img/svg/header-bck.svg") no-repeat center, linear-gradient(to bottom, $grigioFB, $grigioE2);
  background-size: cover;

  .logo {
    margin-bottom: 30px;
    @include breakpoint(xlarge) {
      margin-bottom: 0;
    }    
  }

  h1 {
    line-height: 1;
  }

  .header-left {
    margin-bottom: 50px;
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }

  @include breakpoint(large) {
    padding: 40px 0 85px;
  }
}