.fascia-partner {
  padding: 40px 0 40px;

  .partner-bottom-left {
    position: relative;
    z-index: 2;
    margin-top: 20px;
    background-size: cover;
    height: 400px;

    @include breakpoint (medium) {
      height: auto;
    }
    @include breakpoint (xlarge) {
      margin-top: 35px;
    }
    @include breakpoint (xlarge) {
      margin-top: 85px;
    }
  }
  .partner-bottom-right {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    padding-top: 30px;
    padding-bottom: 60px;
    margin-left: 0;
    background-color: $grigioE2;
    .button {
      position: absolute;
    }

    @include breakpoint (large) {
      margin-bottom: 100px;
      padding: 60px 40px 60px 10.3%;
      margin-left: -8.3%;
    }
  }
}