@font-face {
  font-family: 'milani';
  src: url('../fonts/milani/milani.eot?54304521');
  src: url('../fonts/milani/milani.eot?54304521#iefix') format('embedded-opentype'),
       url('../fonts/milani/milani.woff2?54304521') format('woff2'),
       url('../fonts/milani/milani.woff?54304521') format('woff'),
       url('../fonts/milani/milani.ttf?54304521') format('truetype'),
       url('../fonts/milani/milani.svg?54304521#milani') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-mi-"]:before,
[class*="icon-mi-"]:before,
%icon-mi:before{
  font-family: "milani";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  text-align: center;
  text-transform: none;

  //line-height: 1em;

  display: inline-block;
  vertical-align: top;

  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mi-checked:before { content: '\e804'; } /* '' */