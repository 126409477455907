.fascia-perche {
  overflow: hidden;
  .card-perche {
    position: relative;
    margin: 10px auto;
    background-size: cover;
    background-position: center;

    @include breakpoint (large) {
      margin: 40px auto;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($blu, .91);
      transition: all .5s;

      @include breakpoint (large) {
        background-color: rgba($bluscuro, .69);
      }
    }

    .card {
      position: relative;
      padding: 35px 20px;
      z-index: 1;
      color: $white;
      height: 100%;
      height: calc(100% - 30px);
      margin: 15px;
      border: 1px solid $white;
      transition: all .5s;
      h4 {
        color: $white;
      }
      p {
        font-size: 17px;
        line-height: 22px;

        @include breakpoint (xxlarge) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      @include breakpoint (large) {
        padding: 55px 40px;
        border: 1px solid transparent;
      }

      @include breakpoint (xxlarge) {
        padding: 95px 60px;
      }
    }

    @include breakpoint (large) {
      &:hover {
        &::before {
          background-color: rgba($blu, .91)
        }
        .card {
          border: 1px solid $white;
        }
      }
    }
  }
}