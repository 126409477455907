.fascia-help {
  padding: 55px 0 39px;
  background-color: $blu;
  color: $white;
  h3 {
    color: $white;
    line-height: 30px;

    @include breakpoint(large) {
      line-height: 40px;
    }
  }
}